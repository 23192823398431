<template>
    <div class="container content">
        <top-navigation-bar
            title="Distributions"
            right-nav="home"
        />
        <div class="d-flex route-btn-view">
            <router-link
                :to="{name: 'distributions/binomial'}"
                tag="button"
                class="route-btn general"
            >
                Binomial
            </router-link>
            <router-link
                :to="{name: 'distributions/normal-distribution'}"
                tag="button"
                class="route-btn general"
            >
                Normal Distribution
            </router-link>
            <router-link
                :to="{name: 'distributions/t-distribution'}"
                tag="button"
                class="route-btn general"
            >
                t Distribution
            </router-link>
            <router-link
                :to="{name: 'distributions/chi-square'}"
                tag="button"
                class="route-btn general"
            >
                Chi-Square Distribution
            </router-link>
            <router-link
                :to="{name: 'distributions/f-distribution'}"
                tag="button"
                class="route-btn general"
            >
                F Distribution
            </router-link>
        </div>
    </div>
</template>

<script>
import TopNavigationBar from '@/components/top-navigation-bar.vue'
export default {
    name: 'Distributions',
    components: {
        TopNavigationBar
    }
}
</script>
